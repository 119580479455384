import {mapState, mapActions} from 'vuex'
import {getUser} from '@/function/auth'
import {required, maxLength, minLength} from 'vuelidate/lib/validators'
import ROUTE_NAME from '@/router/routeName'

const inputMaxLength = 255

export default {
    data() {
        return {
            inputMaxLength,

            loading: true,
            disabledForm: false,

            title: {
                primary: 'Project',
                secondary: 'Project Setting',
            },

            breadcrumbsRouteList: [
                {
                    title: 'Project',
                },
                {
                    title: 'Project Setting',
                }
            ],

            form: {
                client_id: '',
                projects: '',
                protocol: 'https://',
                domain: '',
                description: '',
                region_id: undefined,
                url_fid: '',
                is_seowork_project: false,
                flags: {},
                search: [
                    {
                        region_positions: '',
                        region_demand: '',
                        is_mobile: false,
                    },
                ]
            },

            ROUTE_NAME
        }
    },

    validations: {
        form: {
            domain: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(inputMaxLength)
            },

            description: {
                required,
                maxLength: maxLength(inputMaxLength)
            },

            region_id: {
                required
            }
        }
    },

    computed: {
        ...mapState({
            profile: state => state.profile,
            regions: state => state.options.regions,
            projectFlags: state => state.dashboard.projects.projectFlags,
        }),

        url() {
            return this.protocol + this.domain
        },

        searchData() {
            let searchData = [
                {
                    region_positions: '',
                    region_demand: '',
                    is_mobile: false,
                },
            ]
            return
        },

        isRootUser() {
            return getUser().isRoot()
        },

        isAdminUser() {
            return getUser().isAdministrator()
        },
    },

    methods: {
        ...mapActions({
            getRegions: 'getRegions',
            getProjectFlags: 'dashboard/projects/getProjectFlags',
            createProject: 'dashboard/projects/createProject',
        }),

        deleteSearchItem(i) {
            this.form.search.splice(i, 1)
        },

        toggleSearchData(action) {
            switch (action) {
                case 'add': {
                    this.$set(this.form.search, this.form.search.length,
                        {
                            region_positions: '',
                            region_demand: '',
                            is_mobile: false,
                        }
                    )
                    break
                }
                case 'delete': {
                    this.$set(this.form, 'search', [
                        {
                            region_positions: '',
                            region_demand: '',
                            is_mobile: false,
                        },
                    ])
                    break
                }
            }
        },

        async submit() {
            this.$v.form.$touch()
            if (this.$v.form.$invalid) {
                return
            }
            if (!this.disabledForm) {
                this.disabledForm = true
                let form = {
                    client_id: this.profile.client.id,
                    url: this.form.protocol + this.form.domain,
                    description: this.form.description,
                    region_id: this.form.region_id,
                    url_fid: this.form.url_fid,
                    flags: this.form.flags
                }
                try {
                    await this.createProject(form).then(() => {
                        this.$notify({type: 'info', title: 'Проект успешно создан'})
                        this.$router.push({ name: 'desktopProjectsList' })
                    }, error => {
                        if(typeof error === 'object') {
                            for (let item in error) {
                                this.$notify({
                                    type: 'error',
                                    title: error[item]
                                })
                            }
                        } else {
                            this.$notify({
                                type: 'error',
                                title: error
                            });
                        }
                    })
                } catch (e) {
                    this.$notify({type: 'error', title: e.message})
                    this.view_error[e.el] = true
                }
            }
            this.disabledForm = false
        }
    },

    watch: {
        'form.domain'() {
            if (!!this.form.domain && this.form.domain.indexOf('https://') >= 0) {
                this.form.domain = this.form.domain.replace('https://', '')
                this.form.protocol = 'https://'
            }
            if (!!this.form.domain && this.form.domain.indexOf('http://') >= 0) {
                this.form.domain = this.form.domain.replace('http://', '')
                this.form.protocol = 'http://'
            }
        },

        'form.region_id'() {
            if (this.form.region_id.length) this.getRegions({
                search: this.form.region_id,
            })
        },
    },

    async created() {
        try {
            if (!this.projectFlags.length) {
                await this.getProjectFlags()
            }
        } catch (e) {
            this.$notify({type: 'error', title: e})
        }

        this.loading = false
    }
}
